<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import DatePickerInput from './DatePickerInput'
import { TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        DatePickerInput,
    },
    mixins: [ErrorMsgInputMixin],
    props: {
        required: Boolean,
        value: {
            type: Number,
            default: 0,
        },
        reference: {
            type: String,
            required: true,
        },
    },
    computed: {
        referenceDate() {
            return this.$date(this.reference)
        },
        referenceString() {
            return this.referenceDate.format(TIME_FORMAT)
        },
        valueTime: {
            get() {
                return this.referenceDate
                    .subtract(this.value, 'm')
                    .format(TIME_FORMAT)
            },
            set(newTime) {
                const timeParts = newTime.split(':')
                const date = this.referenceDate
                    .set('hour', parseInt(timeParts?.[0]))
                    .set('minute', parseInt(timeParts?.[1]))

                this.$emit('input', this.referenceDate.diff(date, 'm'))
            },
        },
    },
}
</script>

<template>
    <date-picker-input
        v-model="valueTime"
        :label="label"
        :name="name"
        :error-messages="errorMessages"
        :max="referenceString"
        only-time
    ></date-picker-input>
</template>
