<template>
    <div>
        <div
            class="pt-3 pb-6 canteen-restaurant-headline d-flex justify-space-between"
        >
            <v-input
                v-model="form.relatedRestaurants"
                v-validate="'required'"
                hide-details="auto"
                name="relatedRestaurants"
                :data-vv-as="$t('labels.restaurants')"
                :error-messages="errors.collect('relatedRestaurants')"
            >
                <h3 class="headline">
                    {{ $t('labels.restaurants') }}
                </h3>
            </v-input>
            <add-canteen-restaurant
                :type="type"
                class="mt-1"
                :base-pickup-offset="form.pickupOffset"
                :base-cutoff="form.cutoffTime"
                @added="addCanteenRestaurant"
            ></add-canteen-restaurant>
        </div>

        <v-row
            v-for="(relation, index) in form.relatedRestaurants"
            :key="relation['@id']"
            class="pt-2 mb-n2 canteen-restaurant-row"
        >
            <v-col cols="6" md="4" order="0" class="py-0">
                <entity-select-input
                    v-model="relation.restaurant"
                    v-validate="'required'"
                    :name="`relationRestaurant[${relation['@id']}]`"
                    label="labels.restaurant"
                    :data-vv-as="$t('labels.restaurant')"
                    :entity="restaurantEntity"
                    :query-params="restaurantQueryParam"
                    :items-filter="canteenRestaurantsFilter(relation)"
                    :needs-update="relationSelectUpdate"
                    :error-messages="
                        errors.collect(`relationRestaurant[${relation['@id']}]`)
                    "
                    :disabled="!isRelationRemovable(relation)"
                    @input="relationSelectUpdate = !relationSelectUpdate"
                ></entity-select-input>
            </v-col>
            <v-col cols="12" md="5" order="3" order-md="1" class="py-0">
                <relative-date-time-picker-input
                    v-if="!relation.rules.length"
                    v-model="relation.cutoffTimeOverride"
                    v-validate="'required'"
                    label="labels.cc_cutoff.date"
                    :name="`cutoffTimeOverride[${relation['@id']}]`"
                    :reference="form.deliveryTime"
                    :error-messages="
                        errors.collect(`cutoffTimeOverride[${relation['@id']}]`)
                    "
                    :data-vv-as="$t('labels.cc_cutoff.date')"
                    :max="form.deliveryTime"
                ></relative-date-time-picker-input>
            </v-col>
            <v-col cols="5" md="2" order="1" order-md="2" class="py-0">
                <relative-time-picker-input
                    v-model="relation.pickupOffset"
                    v-validate="'required'"
                    label="residential_canteen.pickup_time"
                    :name="`pickupTime[${relation['@id']}]`"
                    :reference="form.deliveryTime"
                    :error-messages="
                        errors.collect(`pickupTime[${relation['@id']}]`)
                    "
                    :data-vv-as="$t('residential_canteen.pickup_time')"
                ></relative-time-picker-input>
            </v-col>
            <v-col cols="1" order="2" order-md="3" class="pb-0 pt-3">
                <v-btn
                    v-if="isRelationRemovable(relation)"
                    icon
                    color="error"
                    @click="removeRestaurantRelation(index)"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-col>
            <v-col
                v-if="messages[index] && messages[index].length > 0"
                cols="12"
                md="8"
                order="4"
                order-md="5"
            >
                <div class="d-flex warning--text">
                    <v-icon color="warning">warning</v-icon>
                    <div class="ml-2">
                        <div
                            v-for="(message, idx) in messages[index]"
                            :key="`${index}-${idx}`"
                        >
                            {{ message }}
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" order="5" order-md="4" class="pt-0">
                <checkbox-input
                    v-model="relation.isNotificationEnabled"
                    :name="`isNotificationEnabled[${relation['@id']}]`"
                    label="labels.canteen_restaurant_notification"
                ></checkbox-input>
            </v-col>
            <v-col cols="12" order="6" class="">
                <multi-cutoff-form-part
                    v-model="relation.rules"
                    :new-rule="{
                        cutoffTime: relation.cutoffTimeOverride,
                        limit: 999,
                    }"
                    :reference-date="form.deliveryTime"
                    :validation-prefix="index"
                >
                    <h4 class="font-weight-regular">
                        {{ $t('labels.canteen_order_limits') }}
                    </h4>
                </multi-cutoff-form-part>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import CanteenBeforeOpenTimeMixin from '@/components/mixins/CanteenBeforeOpenTimeMixin'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import RelativeDateTimePickerInput from '@/components/form/Inputs/RelativeDateTimePickerInput'
import RelativeTimePickerInput from '@/components/form/Inputs/RelativeTimePickerInput'
import AddCanteenRestaurant from '@/components/pages/canteen/AddCanteenRestaurant'
import MultiCutoffFormPart from '@/components/pages/canteen/MultiCutoffFormPart'
import { DATE_TIME_FORMAT, TIME_FORMAT } from '@/const/DateTimeFormats'
import { getId } from '@/services/IdFromIri'
import { fromEntityRules } from '@/services/mapper/RestaurantFormMapper'

export default {
    components: {
        EntitySelectInput,
        CheckboxInput,
        RelativeDateTimePickerInput,
        RelativeTimePickerInput,
        AddCanteenRestaurant,
        MultiCutoffFormPart,
    },
    mixins: [FormPartMixin, CanteenBeforeOpenTimeMixin],
    props: {
        type: {
            type: String,
            required: true,
        },
        restaurantEntity: {
            type: String,
            required: true,
        },
        restaurantQueryParam: {
            type: Object,
            default: () => ({}),
        },
        company: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            relationSelectUpdate: false,
            modelChanged: false,
            openingTimePromises: {},
            messages: [],
        }
    },
    computed: {
        deliveryDate() {
            return this.$date(this.form?.deliveryTime || undefined)
        },
    },
    watch: {
        value: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal?.id !== oldVal?.id) {
                    this.modelChanged = true
                    this.$nextTick(function () {
                        this.modelChanged = false
                    })
                }
            },
        },
        ['form.deliveryTime'](newTime, oldTime) {
            if (this.modelChanged) return

            const diff = this.$date(newTime).diff(oldTime, 'd')
            if (diff != 0) {
                if (this.form.relatedRestaurants.length > 0) {
                    this.form.relatedRestaurants.forEach((relation) => {
                        if (relation.cutoffTimeOverride) {
                            relation.cutoffTimeOverride = this.stepDay(
                                relation.cutoffTimeOverride,
                                diff
                            )
                        }
                    })
                } else {
                    this.form.cutoffTime = this.stepDay(
                        this.form.cutoffTime,
                        diff
                    )
                    this.form.earliestCutoffTime = this.stepDay(
                        this.form.earliestCutoffTime,
                        diff
                    )
                }
            }
        },
        ['form.relatedRestaurants']: {
            deep: true,
            handler(newRelations) {
                this.$set(this, 'messages', [])
                newRelations.forEach((relation) => {
                    this.messages.push(this.getMessages(relation))
                })

                if (this.modelChanged) return

                let latestCutoff = null
                let earliestCutoff = null
                newRelations.forEach((relation) => {
                    if (relation.rules.length > 0) {
                        relation.cutoffTimeOverride =
                            relation.rules[relation.rules.length - 1].cutoffTime
                    }

                    if (relation.cutoffTimeOverride) {
                        const cutoff = this.$date(relation.cutoffTimeOverride)
                        latestCutoff =
                            !latestCutoff || latestCutoff.isBefore(cutoff)
                                ? cutoff
                                : latestCutoff
                        earliestCutoff =
                            !earliestCutoff || earliestCutoff.isAfter(cutoff)
                                ? cutoff
                                : earliestCutoff
                    }
                })

                if (latestCutoff) {
                    this.form.cutoffTime = latestCutoff.format(DATE_TIME_FORMAT)
                }
                // for openFrom validation
                if (earliestCutoff) {
                    this.form.earliestCutoffTime = earliestCutoff.format(
                        DATE_TIME_FORMAT
                    )
                }
            },
        },
    },
    methods: {
        isRelationRemovable(relation) {
            return typeof relation['@id'] === 'number' || this.beforeOpenTime
        },
        removeRestaurantRelation(index) {
            this.form.relatedRestaurants.splice(index, 1)
            this.relationSelectUpdate = !this.relationSelectUpdate
        },
        addCanteenRestaurant(val) {
            if (
                this.form.relatedRestaurants &&
                Array.isArray(this.form.relatedRestaurants)
            ) {
                this.$set(this.form, 'relatedRestaurants', [
                    ...this.form.relatedRestaurants,
                    val,
                ])
            } else {
                this.$set(this.form, 'relatedRestaurants', [val])
            }
        },
        canteenRestaurantsFilter(relation) {
            return (values) =>
                values.filter(
                    (value) =>
                        value.key === relation.restaurant ||
                        !this.form.relatedRestaurants ||
                        this.form.relatedRestaurants.length === 0 ||
                        !this.form.relatedRestaurants
                            .map((rel) => rel.restaurant)
                            .includes(value.key)
                )
        },
        stepDay(dateStr, days) {
            return this.$date(dateStr).add(days, 'd').format(DATE_TIME_FORMAT)
        },
        getMessages(relation) {
            const now = this.$date()
            const messages = []
            if (
                now.isBefore(this.form.originalEarliestCutoffTime) &&
                now.isAfter(relation.cutoffTimeOverride)
            ) {
                messages.push(
                    this.$t('texts.restaurant_cutoff_canteen_uneditable')
                )
            }

            if (relation.restaurant) {
                if (!this.openingTimePromises[relation.restaurant]) {
                    this.openingTimePromises[
                        relation.restaurant
                    ] = RestaurantsApiClient.get(getId(relation.restaurant))
                }

                const rT = this.$t('labels.open_hours_for_menu')

                this.openingTimePromises[relation.restaurant]
                    .then((data) => {
                        const pickup = this.deliveryDate.subtract(
                            relation.pickupOffset,
                            'm'
                        )
                        const field = `pickupTime[${relation['@id']}]`

                        const open = this.isRestaurantOpenAt(data, pickup)
                        if (open !== true) {
                            messages.push(`${rT}: ${open}`)
                            this.errors.add({
                                field,
                                msg: this.$t(
                                    'texts.restaurant_closed_on_pickup'
                                ),
                            })
                        } else {
                            this.errors.remove(field)
                        }

                        return data
                    })
                    .then((data) => {
                        if (
                            relation.rules.length === 0 &&
                            data.defaultCanteenRules.length > 0
                        ) {
                            relation.rules = fromEntityRules(
                                data,
                                relation.cutoffTimeOverride
                            )
                        }
                        return data
                    })

                const companyField = `relationRestaurant[${relation['@id']}]`

                if (
                    this.company &&
                    (this.company.hiddenRestaurants.includes(
                        relation.restaurant
                    ) ||
                        (this.company.hideAllRestaurants &&
                            !this.company.hideAllExceptions.includes(
                                relation.restaurant
                            )))
                ) {
                    this.errors.add({
                        field: companyField,
                        msg: this.$t('texts.restaurant_disabled_by_company'),
                    })
                } else {
                    this.errors.remove(companyField)
                }
            } else if (relation.rules.length > 0) {
                relation.rules = []
            }

            return messages
        },
        isRestaurantOpenAt(restaurant, date) {
            let weekday = date.day()
            weekday = weekday === 0 ? 7 : weekday

            let openingDay = null
            restaurant.openingDays.forEach((day) => {
                if (day.day === weekday) {
                    if (
                        (!openingDay && !day.date) ||
                        (day.date && date.isSame(day.date, 'd'))
                    ) {
                        openingDay = day
                    }
                }
            })

            if (!openingDay) return this.$t('labels.closed_all_day')

            const timeDate = date.set('y', 1970).set('M', 0).set('D', 1)
            const intervals = []

            for (let interval of openingDay.timeIntervals) {
                if (
                    timeDate.isSameOrAfter(interval.openTime) &&
                    timeDate.isSameOrBefore(interval.closeTime)
                ) {
                    return true
                } else {
                    const open = this.$date(interval.openTime).format(
                        TIME_FORMAT
                    )
                    const close = this.$date(interval.closeTime).format(
                        TIME_FORMAT
                    )
                    intervals.push(`${open} - ${close}`)
                }
            }

            if (intervals.length === 0) return this.$t('labels.closed_all_day')

            return intervals.join(', ')
        },
    },
}
</script>

<style>
.canteen-restaurant-headline .v-input__slot {
    margin-bottom: 0;
}
.canteen-restaurant-row:nth-child(2n) {
    background-color: #f5f5f5;
}
</style>
