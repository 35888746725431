export default {
    data() {
        return {
            timerInterval: null,
        }
    },
    mounted() {
        this.onTimerUpdate()
        this.timerInterval = setInterval(this.onTimerUpdate, 1000)
    },
    beforeDestroy() {
        clearInterval(this.timerInterval)
    },
    methods: {
        onTimerUpdate() {
            // to be overridden by using components
        },
    },
}
