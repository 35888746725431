import baseFormType from '@/store/type/baseFormType'
import TimerCallbackMixin from '@/components/mixins/TimerCallbackMixin'

export default {
    mixins: [TimerCallbackMixin],
    data() {
        return {
            showProgress: true,
            now: null,
        }
    },
    beforeDestroy() {
        this.$store.commit(`baseForm/${baseFormType.mutations.SET_BUSY}`, false)
    },
    computed: {
        showWarning() {
            return (
                this.form.id &&
                this.now
                    ?.add(5, 'minutes')
                    .isAfter(this.form.originalEarliestCutoffTime, 'second')
            )
        },
        warningMessage() {
            if (this.disabled && !this.showProgress) {
                return this.$t('texts.canteen_after_cutoff_edit')
            } else if (this.showWarning) {
                const diff = this.$date(
                    this.form.originalEarliestCutoffTime
                ).diff(this.now, 'second')
                const minutes = Math.floor(diff / 60)
                const seconds = String(diff % 60).padStart(2, '0')
                return this.$t('texts.canteen_edit_disabled_in', {
                    time: `${minutes}:${seconds}`,
                })
            }
            return ''
        },
    },
    methods: {
        onTimerUpdate() {
            this.now = this.$date()
            if (
                this.form.id &&
                this.form.originalEarliestCutoffTime &&
                this.now.isAfter(this.form.originalEarliestCutoffTime, 'second')
            ) {
                this.disabled = true
                this.showProgress = false
            }
        },
    },
}
