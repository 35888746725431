<template>
    <div>
        <h3 class="headline pt-3 pb-6 black--text">
            {{ $t('labels.canteen_occurences_title') }}
        </h3>

        <div class="pb-6">
            <checkbox-input
                v-model="form.isRecurring"
                name="isRecurring"
                label="labels.is-recurring"
                @input="noDateAndRecurring"
            ></checkbox-input>
        </div>

        <text-input
            v-show="form.isRecurring"
            v-model="form.recurringWeeks"
            v-validate="'required|numeric|min:1'"
            type="number"
            name="recurringWeeks"
            label="labels.recurring-weeks"
            :suffix="$t('labels.week')"
            :error-messages="errors.collect('recurringWeeks')"
            :data-vv-as="$t('labels.recurring-weeks')"
            :disabled="!form.isRecurring"
        ></text-input>

        <v-row v-show="form.isRecurring">
            <v-col class="pr-0">
                <date-picker-input
                    v-model="recurDate"
                    label="labels.next_occurence"
                    :min="minRecurDate"
                    only-date
                ></date-picker-input>
            </v-col>
            <v-col class="pr-0">
                <date-picker-input
                    v-model="form.recurrenceEnds"
                    label="labels.recurrence_ends"
                    :min="recurDate"
                    only-date
                ></date-picker-input>
            </v-col>
        </v-row>

        <v-radio-group
            v-show="form.isRecurring"
            v-model="day"
            mandatory
            :label="$t('labels.recurring-day')"
            :disabled="!form.isRecurring"
            class="mt-3"
        >
            <div class="d-flex flex-wrap day-radio-select">
                <v-radio
                    v-for="n in 7"
                    :key="n"
                    :label="$t(`days_of_week.${n - 1}`).slice(0, 2)"
                    :value="n % 7"
                    class="mr-3"
                ></v-radio>
            </div>
        </v-radio-group>

        <v-row>
            <v-col v-show="!form.isRecurring" cols="5" class="pr-0">
                <date-picker-input
                    v-model="deliveryTimeDate"
                    v-validate="'required'"
                    label="labels.cc_delivery_at.date"
                    name="deliveryTimeDate"
                    class="w-100"
                    :error-messages="errors.collect('deliveryTime')"
                    :data-vv-as="$t('labels.cc_delivery_at.date')"
                    only-date
                ></date-picker-input>
            </v-col>
            <v-col :cols="form.isRecurring ? 12 : 7" class="d-flex">
                <date-picker-input
                    v-model="deliveryTimeTime"
                    :label="
                        form.isRecurring
                            ? 'labels.cc_delivery_at.date'
                            : undefined
                    "
                    name="deliveryTimeTime"
                    class="w-100"
                    :error-messages="errors.collect('deliveryTime')"
                    :data-vv-as="$t('labels.cc_delivery_at.date')"
                    only-time
                ></date-picker-input>
                <!-- text field - for design purposes -->
                <v-text-field
                    :value="`${$options.filters.toDate(
                        form.deliveryTime,
                        'HH:mm',
                        30
                    )}`"
                    prepend-inner-icon="remove"
                    class="pl-2"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>

        <relative-date-time-picker-input
            v-model="form.openTime"
            v-validate="'required'"
            label="labels.open-from"
            name="openTime"
            :reference="form.deliveryTime"
            :error-messages="errors.collect('openTime')"
            :data-vv-as="$t('labels.open-from')"
            :max="form.earliestCutoffTime"
            invalid-date
        ></relative-date-time-picker-input>
    </div>
</template>

<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import RelativeDateTimePickerInput from '@/components/form/Inputs/RelativeDateTimePickerInput'
import {
    TIME_FORMAT,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
} from '@/const/DateTimeFormats'

export default {
    components: {
        TextInput,
        CheckboxInput,
        DatePickerInput,
        RelativeDateTimePickerInput,
    },
    mixins: [FormPartMixin],
    data() {
        return {
            modelChanged: false,
        }
    },
    computed: {
        minRecurDate() {
            const $now = this.$date()
            return $now.isAfter(this.form.originalEarliestCutoffTime)
                ? ''
                : $now.format('YYYY-MM-DD')
        },
        recurDate: {
            get() {
                return this.$date(this.form.deliveryTime || undefined).format(
                    DATE_FORMAT
                )
            },
            set(date) {
                this.form.deliveryTime = this.setDate(
                    this.form.deliveryTime,
                    date
                )
            },
        },
        day: {
            get() {
                return parseInt(
                    this.form.deliveryTime
                        ? this.$date(this.form.deliveryTime).format('d')
                        : this.$date().add(1, 'day').format('d')
                )
            },
            set(day) {
                const originalDeliveryWeek = this.$date(
                    this.form.originalDeliveryTime
                ).isoWeek()
                const deliveryDate = this.$date(
                    this.form.deliveryTime || undefined
                )
                const prevDay = deliveryDate.day()

                let newDelivery = deliveryDate.day(day)

                // fix sunday being first day of week...
                if (day === 0 && prevDay !== 0) {
                    newDelivery = newDelivery.add(1, 'w')
                } else if (day !== 0 && prevDay === 0) {
                    newDelivery = newDelivery.subtract(1, 'w')
                }

                const weekDiff = newDelivery.isoWeek() - originalDeliveryWeek
                if (weekDiff !== 0) {
                    newDelivery = newDelivery.subtract(weekDiff, 'w')
                }

                if (this.$date().isSameOrAfter(newDelivery, 'day')) {
                    newDelivery = newDelivery.add(1, 'w')
                }

                this.form.deliveryTime = newDelivery.format(DATE_TIME_FORMAT)
            },
        },
        deliveryTimeDayJs() {
            return this.$date(this.form.deliveryTime || undefined)
        },
        deliveryTimeTime: {
            get() {
                return this.deliveryTimeDayJs.format(TIME_FORMAT)
            },
            set(newTime) {
                this.form.deliveryTime = this.setTime(
                    this.form.deliveryTime,
                    newTime
                )
            },
        },
        deliveryTimeDate: {
            get() {
                return this.deliveryTimeDayJs.format(DATE_FORMAT)
            },
            set(newDate) {
                this.form.deliveryTime = this.setDate(
                    this.form.deliveryTime,
                    newDate
                )
            },
        },
        cutoffTimeMinutes() {
            return this.$date(this.form.cutoffTime || undefined).get('minute')
        },
    },
    watch: {
        value: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal?.id !== oldVal?.id) {
                    this.modelChanged = true
                    this.$nextTick(function () {
                        this.modelChanged = false
                    })
                }
            },
        },
        ['form.deliveryTime'](newTime, oldTime) {
            if (this.modelChanged) return

            const diff = this.$date(newTime).diff(oldTime, 'd')
            if (diff != 0) {
                this.form.openTime = this.$date(this.form.openTime)
                    .add(diff, 'd')
                    .format(DATE_TIME_FORMAT)
            }
        },
    },
    methods: {
        setDate(dateTime, newDate) {
            const date = newDate || this.$date().format(DATE_FORMAT)
            const [year, month, day] = date.split('-')
            let merged = this.$date(dateTime)
            merged = merged.set('year', parseInt(year))
            merged = merged.set('month', parseInt(month) - 1)
            merged = merged.set('date', parseInt(day))
            return merged.format(DATE_TIME_FORMAT)
        },
        setTime(dateTime, newTime) {
            const [hour, minute] = newTime.split(':')
            let merged = this.$date(dateTime)
            merged = merged.set('hour', hour)
            merged = merged.set('minute', minute)
            return merged.format(DATE_TIME_FORMAT)
        },
        noDateAndRecurring() {
            if (
                !this.form.isRecurring ||
                (this.form.cutoffTime && this.form.deliveryTime)
            ) {
                return
            }

            let tomorrow = this.$date().add(1, 'day')

            this.form.cutoffTime = tomorrow.format(DATE_TIME_FORMAT)
            this.form.deliveryTime = tomorrow.format(DATE_TIME_FORMAT)
        },
    },
}
</script>

<style lang="scss">
.day-radio-select {
    .v-radio {
        position: relative;
    }

    .v-input--selection-controls__input {
        $s: 34px;
        width: $s;
        height: $s;
    }

    .v-input--selection-controls__ripple {
        $s: 40px;
        height: $s;
        width: $s;
        top: calc(50% - 26px);
        left: -10px;
        z-index: 1;
    }

    .v-input--radio-group--column & .v-radio:not(:last-child):not(:only-child) {
        margin-bottom: 0;
    }

    .v-input--selection-controls & .v-radio > .v-label {
        $s: 34px;
        display: block;
        position: absolute !important;
        width: $s;
        height: $s;
        line-height: $s;
        border-radius: 50%;
        top: 1px;
        left: 0px !important;
        border: 1px solid var(--v-g-26-base);
        text-align: center;
        font-weight: bold;
        background-color: var(--v-g-e8-base);
    }

    .v-input--selection-controls & .v-radio.v-item--active > .v-label {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
        border-color: var(--v-primary-base);
    }
}
</style>
