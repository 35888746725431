import TimerCallbackMixin from '@/components/mixins/TimerCallbackMixin'

export default {
    mixins: [TimerCallbackMixin],
    data() {
        return {
            beforeOpenTime: true,
        }
    },
    methods: {
        onTimerUpdate() {
            this.beforeOpenTime = this.$date().isSameOrBefore(
                this.form.openTime,
                'minute'
            )
        },
    },
}
